import React, { useState } from "react";
import "./contactuspage.css";
import contactImg from "../../assets/contactCall.svg";
import contactImg1 from "../../assets/contactMail.svg";
import contactImg2 from "../../assets/contactmap.svg";
import axios from "axios";

export default function GetInTouch() {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    city: '',
    cellno: '',
    address: '',
    subject: '',
    postalcode: '',
    message: '',
  });

  const [error, setError] = useState({
    firstname: '',
    lastname: '',
    email: '',
    city: '',
    cellno: '',
    address: '',
    subject: '',
    postalcode: '',
    message: '',
  });

  let getInTouchData = [
    {
      titleInput: "Email Address",
      subTitle: "Info@leducchimneysweep.com",
      imgIcon: contactImg1,
    },
    { imgIcon: contactImg, titleInput: "Call Us", subTitle: "780-986-1181" },
    {
      imgIcon: contactImg2,
      titleInput: "Address",
      subTitle: "#4 5907-45 Street Leduc, AB",
    },
  ];
  const handleSubmit = async (e) => {
    e.preventDefault();

    //add validation of all field
    if (!formData.firstname) {
      setError({ ...error, firstname: 'Please enter your first name' })
    }
    if (!formData.lastname) {
      setError({ ...error, lastname: 'Please enter your last name' })
    }
    if (!formData.email) {
      setError({ ...error, email: 'Please enter your email' })
    }
    if (!formData.city) {
      setError({ ...error, city: 'Please enter your city' })
    }
    if (!formData.cellno) {
      setError({ ...error, cellno: 'Please enter your cell no' })
    }
    if (!formData.address) {
      setError({ ...error, address: 'Please enter your address' })
    }
    if (!formData.subject) {
      setError({ ...error, subject: 'Please enter your subject' })
    }
    if (!formData.postalcode) {
      setError({ ...error, postalcode: 'Please enter your postal code' })
    }
    if (!formData.message) {
      setError({ ...error, message: 'Please enter your message' })
    }
    if (formData.firstname && formData.lastname && formData.email && formData.city && formData.cellno && formData.address && formData.subject && formData.postalcode && formData.message) {
      setError({
        firstname: '',
        lastname: '',
        email: '',
        city: '',
        cellno: '',
        address: '',
        subject: '',
        postalcode: '',
        message: '',
      })

      try {
        const response = await axios.post('http://127.0.0.1:5000/api/users', formData);
        console.log('User created:', response.data);
        // Clear form or show success message as needed
      } catch (error) {
        console.error('Error creating user:', error);
        // Handle error, show error message
      }
    }




  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  console.log(formData)
  return (
    <div className="blk_yellow_container">
      <div className="yellow_outside_container">
        <div className="yellow_container">
          <h6>
            Lets Get in <br />
            <span>Touch</span>
          </h6>

          <div className="three_contact_container">
            {getInTouchData?.map((item, index) => {
              return (
                <div key={index} className="email_contact_container">
                  <div className="contact_img_container">
                    <img src={item?.imgIcon} alt="imgContact" loading="lazy" />
                  </div>
                  <div className="content_container">
                    <p>{item?.titleInput}</p>
                    <a>{item?.subTitle}</a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="black_container">
        <form className="all_inputs_container text-white">
          <input type="text" name="firstname" placeholder="First name" onChange={handleChange} /> <br />
          <p style={{ color: 'red' }}>{error.firstname}</p>
          <input type="text" name="lastname" placeholder="Last Name" onChange={handleChange} /> <br />
          <p style={{ color: 'red' }}>{error.lastname}</p>
          <input type="number" name="cellno" placeholder="Cell No" onChange={handleChange} /> <br />
          <input type="email" name="email" placeholder="Email" onChange={handleChange} /> <br />
          <input type="city" name='city' placeholder="City" onChange={handleChange} /> <br />
          <input type="text" name="address" placeholder="Address" onChange={handleChange} /> <br />
          <input type="text" name="subject" placeholder="Subject" onChange={handleChange} /> <br />
          <input type="number" name="postalcode" placeholder="Postal Code" onChange={handleChange} /> <br />
          <textarea type="text" name="message" placeholder="Message" onChange={handleChange}></textarea>
          <br />
          <button onClick={handleSubmit}>Submit</button>
        </form>
      </div>
    </div>
  );
}
