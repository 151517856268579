// add list of data

export const listOfFaq  = [
    {
    question: "How do I know my Chimney needs cleaning?",
    answer: ` If you notice a soot build up, foul odours, smoke backing up, difficulty starting fires,
 visible debris, or nesting materials. It’s time to get the chimney cleaned.
`,
    },
    {
    question: "What happens if you don’t sweep your chimney?",
    answer: `Neglecting Chimney cleaning can cause Chimney fires, Poor indoor air quality, 
Damage to chimney structure, increased risk of Carbon Monoxide poisoning, 
Nesting Animals and Pets and Decreased heating efficiency.`,
    },
    {
    question: "How often should a chimney need cleaning?",
    answer: `We recommend getting it cleaned annually. The frequency of chimney cleaning 
may also depend on how often you use your fireplace or stove. If you use it heavily
 throughout the heating season, more frequent cleaning may be necessary to prevent 
creosote build-up and maintain safety.
`,
    },
    {
    question: "How to prepare for your Chimney Sweep appointment?",
    answer: `For Chimney Sweep, please don’t have the fire on for at least 48 hours prior 
to the appointment date. - Remove any personal items from the hearth and put
 away any pets prior to our arrival. - Our technicians carry portable debit machines 
so you can pay either by debit or credit card right on the site and 
we also accept e-transfer, cash, and cheque.
`,
    },
    {
        question: " How can a sweep help me to maintain a safe home?",
        answer: `Chimney sweep helps in Preventing Chimney Fires, Removing Blockages and Debris, 
    Identifying Structural Issues, Detecting Carbon Monoxide Leaks, Providing
    `,
        },
];

