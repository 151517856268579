import React from "react";

export default function ContactUsSales() {
  return (
    <div className="contact_us_sales_container">
      <div className="same_width_container">
        <div className="contact_us_card_container">
          <h6>
            Contact <span>Us</span>
          </h6>

          <div className="contact_us_inputs_container">
            <div className="inputs_wrapper">
              <input type="text" placeholder="First Name" />
              <input type="text" placeholder="Last Name" />
              <input type="number" placeholder="Cell No" />
              <input type="email" placeholder="Email" />
              <input type="text" placeholder="City" />
              <input type="text" placeholder="Address" />
              <input type="text" placeholder="Subject" />
              <input type="number" placeholder="Postal Code" />
            </div>
            <textarea placeholder="Message"></textarea>

            <button>Send Now</button>
          </div>
        </div>
      </div>
    </div>
  );
}
