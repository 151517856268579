import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import qoute from "../../assets/qoute.svg";
import profile_pic from "../../assets/star.png";
import profile_pic1 from "../../assets/starempty.png";
import testimonialsection2img from "../../assets/testimonialsection2img.png";
import "./testimonialSection2.css";

export default function TestimonialSection2() {

    let data = [
        {
          id: 1,
          name: "surge001122",
          // position: "CEO",
          image: profile_pic,
          qoute:
            `Good morning to whole ever reads this review, we are new to the Edmonton area and had to get a WETT inspection done at our new home we hired Leduc chimney sweep and they came out and helped us in ways unimaginable  then went above and beyond to help our family get a fire approved and lit for the winter. Thank you to the crew and for the wicked welcome to the area but most of all having a heart in time where family’s need people the most thank you from my family and all the best in the new year🙏

I strongly recommend this company and team to anyone in the area looking to have work done in there home`,
        },
        {
          id: 2,
          name: "Stephanie Brown",
          // position: "CEO",
          image: profile_pic,
          qoute:
           `So impressed with Leduc Chimney Sweep! After another WETT certified company rescheduled on us three times, Leduc Chimney Sweep was easy to work with, found a time that worked for us, and completed the work despite the frigid temperature and snow! Malcolm took the time to explain the procedure and what he was looking for, as well as options to make our fireplace more efficient. Wonderful to work with and would definitely recommend to others.`
        },
        {
          id: 3,
          name: "Johnnie Guitar",
          // position: "CEO",
          image: profile_pic,
          qoute:`Neighbors in our condominium complex have called on Leduc Fireplace for chimney inspections and cleaning in the past, so my wife and I felt confident about contacting them when we were ready to replace our wood burning fireplace with a gas one. We're glad we did! Their assistance in choosing a modern gas unit, removing the old wood burning fireplace, installing the new one, testing it and instructing us in the proper use of it was professionally done with attention to the smallest detail. These people know fireplaces! You might find a lower price on a fireplace somewhere else, but I"m betting that you won't find the high level of expertise, knowledge, and dependable service that you get with Leduc Fireplace. Karen, Ralph, Dustin and the others are focused on Customer Satisfaction in every way, and I highly recommend giving them a call for any of your fireplace needs.`
        },
        {
          id: 4,
          name: "Tena Stewart",
          // position: "CEO",
          image: profile_pic,
          qoute:`Wonderful experience!!
We had our gas fireplace serviced. The service was prompt and efficient. Dustin was a pleasure to deal with, very knowledgeable and happy to answer questions. Sheena was fabulous to deal with over the phone. She booked my appointment in very quickly and was great when communicating through email. This is the only company I will tell all my family and friends to go to for service!`
        },
    ]

  return (
    <div className="testimonial_section_two_wrapper">
       <div className="right_container_two">
        <div className="">
          <img
            src={testimonialsection2img}
            alt="testimonialsection2img"
            loading="lazy"
          />
        </div>
      </div>
      <div className="left_container_two">
        <p>
          Our <span>Testimonials</span>
        </p>

        <Swiper
         pagination={{
          clickable: true,
        }}
          slidesPerView={1} 
          spaceBetween={30}
         

          modules={[Pagination, Navigation, Autoplay]}
          className="mySwiper swiperTestimonial"
        >
          {data?.map((item) => {
            return (
              <SwiperSlide>
                <div className="box_container_slider_testimonial_two">
                  <img
                    className="qoute_self"
                    src={qoute}
                    alt="qoute"
                    loading="lazy"
                  />
                  <p>
                    “ {item?.qoute?.slice(0,500)+ "..." } ”
                  </p>

                  <div className="profile_pic_wrapper">
                    
                    {[1,2,3,4,5].map((item) => {
                      return (
                        <img
                          src={profile_pic}
                          alt="profile_pic"
                          loading="lazy"
                        />
                      );
                    })}

                  </div>

                  <p>
                    {item?.name}<span>{item?.position ? "/"+ item.position  : null}</span>
                  </p>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
     
    </div>
  );
}
