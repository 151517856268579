import React from "react";
import image1 from "../../assets/repairimage1.png";
import image2 from "../../assets/image2.png";
import arrow from '../../assets/arrow.png'

import './service.css';

const ServiceSection = () => {

    let services = [
        {
            id: 1,
            title: 'Chimney Sweeping, Inspection and Installation',
        },
        {
            id: 2,
            title: 'Fireplaces Supplies',
        },
        {
            id: 3,
            title: 'Masonry work',
        },
        {
            id: 4,
            title: 'WETT Inspections',
        },
    ];
 
    return (
        <div className="service_container ">
            <div className="service_content">
            <div className="service_content_left">
                    <div className="service_left_img_container">

                    <img src={image2} alt="Service" className="service_image1" />
                    </div>
                    {/* <img src={image2} alt="Service" className="service_image2" /> */}
                </div>
                <div className="service_content_right">
                    <h1 className="service_title">Weaving Warmth, <br/> <p>Season After Season:</p></h1>
                    <p className="service_description">At Leduc Chimney Sweep, we offer a range of services designed to
                        keep your fireplace burning safely and without hindrances, these
                        include</p>
                    <div className="service_list">
                        {services.map((item, i) => (
                            <div className="service_list_item_container" key={i}>
                                <img src={arrow} />
                                <p className="service_list_item_title">{item?.title}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}


export default ServiceSection;