import React from 'react'
import HeaderSection from '../../components/header'
import CommonHeroSection from '../../components/CommonHeroSection/CommonHeroSection'
import ChimneySweepSection from './ChimneySweepSection'
import serviceman from '../../assets/chimneySweepBg1.png'

import Footer from '../../components/footerSection'
import image2 from "../../assets/serviceBg.png";
import OurGlorySection from '../../components/OurGlorySection'

import faqImg from '../../assets/webInspectionImg.png'
import ContactUsSection from '../../components/servicesSection/ContactUsSection'
import FAQsSection from '../../components/FAQsSection'
import { listOfFaq } from '../../components/listofData'
import webInspectionImgfaq from '../../assets/webInspectionImg.png'

import wood1 from '../../assets/woodglory1.jpg'
import wood2 from '../../assets/woodglory6.jpg'
import wood3 from '../../assets/woodglory3.jpg'
import wood4 from '../../assets/woodglory4.jpg'


export default function ServicePageWetInsception() {


    return (
        <div>
            <HeaderSection

            />
            <CommonHeroSection
                imgName={image2}
                titleHead="WETT Inspection"
                extraContent={`Home > Our Services > WETT Inspection`}
            />
            <ChimneySweepSection
                firstSentence={`WETT Inspection with`}
                secSentence={`Leduc Chimney Sweep Ltd`}
                thirdSentence={`.The Expert Eye for your chimney’s health.`}
                title={`Chimney Inspection:`}
                subTitle={` The WETT 
            Way it goes!`}
                extraContent={`WETT Inspection provides a thorough checkup for your wood-
            burning fireplace or appliance. Certified WETT inspectors are 
            trained to identify potential safety hazards like creosote buildup
             and ensure your chimney system is properly installed and 
            functioning efficiently. From the firebox and damper to the flue
             and chimney itself everything is carefully inspected.`}
             bgImg={serviceman}

            />
            <FAQsSection
                firstSentence={`Get safe and secure from fire hazards of fireplace. Get your WETT Certified Inspection With`}
                secSentence={` Leduc Chimney Sweep`}
                thirdSentence={` TODAY!`}
                faqImg={faqImg}

                ExpandDataAll={listOfFaq}
            />
            <OurGlorySection 
            images={[wood1,wood2,wood3,wood4]}
            />
            <ContactUsSection />

            <Footer />
        </div>
    )
}
