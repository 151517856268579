import React from "react";
import "./expertsection.css";
import expertChimneyImg from '../../assets/expert_chimney_bg.png'

export default function ExpertSection() {
  return (
    <div>
      <div className="same_width_container">
        <div className="left_rgt_expert_container">
          <div className="left_expert_container">
            <div className="left_expert_img_container">
              <img src={expertChimneyImg} alt="bg" loading="lazy" />
            </div>
          </div>
          <div className="rgt_expert_container">
            <div className="rgt_expert_content_container">
              <p>Your Go-To Chimney </p>
              <h6><span>E</span>xperts</h6>
              <p>
                Craving the crackle of a wood fire? Longing for the sleek
                modernity of an electric fireplace? Leduc Chimney Sweep Ltd.
                makes it possible for you. We're not just chimney sweep in
                Edmonton, Alberta – we're your one-stop shop for all things
                right. Our team of certified professionals has the expertise and
                experience to ensure your chimney functions flawlessly, season
                after season.
              </p>
              <button>Discover More</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
