import React from 'react'
import HeaderSection from '../../components/header'
import CommonHeroSection from '../../components/CommonHeroSection/CommonHeroSection'
import ChimneySweepSection from './ChimneySweepSection'
import Footer from '../../components/footerSection'
import image2 from "../../assets/serviceBg.png";
import OurGlorySection from '../../components/OurGlorySection'

import MassonaryImgSec from '../../assets/MassonaryImgSec.png'
import ContactUsSection from '../../components/servicesSection/ContactUsSection'
import FAQsSection from '../../components/FAQsSection'
import MassonaryImg from '../../assets/MassonaryImg.png'
import { listOfFaq } from '../../components/listofData'
import  masonaryglory1 from '../../assets/masonaryglory1.jpg'
import  masonaryglory2 from '../../assets/masonaryglory2.jpg'
import  masonaryglory3 from '../../assets/masonaryglory3.jpg'
import  masonaryglory4 from '../../assets/masonaryglory4.jpg'
// import  masonaryglory5 from '../../assets/masona'



export default function ServicePageIMassonary() {


    let images = [ masonaryglory1, masonaryglory2, masonaryglory3, masonaryglory4]

    const ExpandDataAll = [
        {
            question: "What is Masonry Work?",
            answer: `Masonry work is the skilled craft of constructing structures using bricks, stones,
            concrete blocks, or similar units. These units are typically laid in horizontal 
           courses and bound together with mortar, a paste that hardens over time.`,
        },
        {
            question: "When is the correct time for masonry work?",
            answer: `You need to clean your chimney to prevent chimney fires and ensure efficient 
                  burning. Soot buildup is flammable, and a clean chimney allows proper airflow 
                  for optimal heat and safety.`,
        },
        {
            question: "HOW do we choose the material for Masonry work?",
            answer: `You need to clean your chimney to prevent chimney fires and ensure efficient 
                  burning. Soot buildup is flammable, and a clean chimney allows proper airflow 
                  for optimal heat and safety.`,
        },
        {
            question: "WHY is masonry work needed?",
            answer: `You need to clean your chimney to prevent chimney fires and ensure efficient 
                  burning. Soot buildup is flammable, and a clean chimney allows proper airflow 
                  for optimal heat and safety.`,
        },
    ];


    return (
        <div>
            <HeaderSection

            />
            <CommonHeroSection
                imgName={image2}
                titleHead="Masonary Work"
                extraContent={`Home > Our Services > Masonary/work`}
            />
            <ChimneySweepSection
            bgImg={MassonaryImg}
            beforeAfter={true}
                firstSentence={`WETT Inspection with`}
                secSentence={`Leduc Chimney Sweep Ltd`}
                thirdSentence={`.The Expert Eye for your chimney’s health.`}
                title={` Expert Masonry Services`}
                subTitle={` Strength`}
                extraContent={`For all your masonry needs, Leduc Chimney Sweep Ltd. is the 
                trusted choice. We go beyond chimney sweeping and offer expert
                 craftsmanship for any brick or stone project. Our skilled masons
                 use their experience to create beautiful, long-lasting results,
                 ensuring the strength and stability of your home. 
                Whether you need repairs, restoration, or brand new installations,
                 Leduc Chimney Sweep Ltd. guarantees top-quality service with a 
                focus on precision and customer satisfaction. 
                Let us transform your vision into a reality with 
                our expertise in masonry work.`}

            />
            <FAQsSection
                firstSentence={`The Art of Masonry: Transforming Stone into Masterpieces By`}
                secSentence={` Leduc Chimney Sweep Ltd.`}
                thirdSentence={` TODAY!`}
                faqImg={MassonaryImgSec}

                ExpandDataAll={listOfFaq}
            />
            <OurGlorySection 
            images={images}
            />
            <ContactUsSection />

            <Footer />
        </div>
    )
}
